import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function BlogDetailComponent() {
  const [blog, setBlogDetail] = React.useState(null);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      axios
        .get(`https://glauco-lima-backend.herokuapp.com/blog/${slug}`)
        .then((response) => {
          console.log(response);
          setBlogDetail(response.data);
        })
        .catch((error) => {
          throw error;
        });
    };

    fetchBlogDetail();
  }, [setBlogDetail, slug]);

  return (
    <div className='blog-detail'>
      <Fade>
        <h1 className='page-heading oswald blog-title'>Blog</h1>
      </Fade>
      <Fade>
        <div className='blog-posts'>
          {/* <h1>{blog}</h1> */}
          {blog && (
            <Container key={blog._id}>
              <h2 className='blog-title'>{blog.title}</h2>
              <p className='blog-description'>{blog.shortDescription}</p>
            </Container>
          )}
        </div>
      </Fade>
    </div>
  );
}
