/** @fileoverview navLinks is an array of objects containing the text and the path for the navbar. */

// The user can modify links in the navbar by simply changing the things here.
const navLinks = [
	{
		text: 'Home',
		path: '/',
		id: 'home',
	},
	{
		text: 'Bio',
		path: '/#bio',
		id: 'biography',
	},
	{
		text: 'Blog',
		path: '/blog',
		id: 'blog',
	},
	{
		text: 'Discography',
		path: '/discography',
		id: 'discography',
	},
	{
		text: 'Artists',
		path: '/artists',
		id: 'artists',
	},
	{
		text: 'Videos',
		path: '/videos',
		id: 'videos',
	},
	{
		text: 'Projects',
		path: '/projects',
		id: 'projects',
	},
	{
		text: 'Music and Society',
		path: '/music-and-society',
		id: 'music-and-society',
	},
	{
		text: 'Press Links',
		path: '/press-links',
		id: 'press-links',
	},
	{
		text: 'Online Music Courses',
		path: '/music-courses',
		id: 'music-courses',
	},
	{
		text: 'Latest Updates',
		path: '/latest-updates',
		id: 'latest-updates',
	},
];

export default navLinks;
