import React from 'react';
import { Carousel, Container } from 'react-bootstrap';

const discographyInfo = {
  title: 'Once Upon a Time',
  reviews: [
    {
      quote:
        "Lima's fantastic ability to entrance the listener through multiple styles and his piano playing, full of dynamics displays a rich musical maturity",
      author: 'Geannine Reid',
      source: 'AllAboutJazz',
      url: 'https://www.allaboutjazz.com/once-upon-a-time-glauco-lima-self-produced-review-by-geannine-reid',
    },
    {
      quote:
        "His compositions take an innovative approach and yet keep the rich tradition of jazz. It's definitely an enjoyable journey listening to the whole album! The result a sound that is at once jazz and Brazilian and the combination is infectious!",
      author: 'Grady Harp',
      source: 'Amazon',
      url: 'https://www.amazon.com/Once-Upon-Time-Glauco-Lima/dp/B0164IHTYG',
    },
    {
      quote:
        "With a handful of covers and original tracks, Lima is accompanied by a myriad of talented musicians who each show an intuitive inclination to keep on the same page with him. The harmonic forms which they scroll have an elegant choreography and a flawless luster. Once Upon a Time expresses his penchant for creativity and experience in the contemporary jazz forum.",
      author: 'Susan Frances',
    },
    {
      quote:
        "Glauco’s jazz piano work will be a (totally) new experience for your ears… there is often a tendency for jazz pianists to try to “play like someone else”, but his strident style shows both his classical and jazz roots on all of the songs he offers up for your adventuresome ears…I give Glauco and crew a MOST HIGHLY RECOMMENDED, with an “EQ” (energy quotient) rating of 4.98 for this fine release.",
      author: 'ROTCODZZAJ',
      url: 'http://rotcodzzaj.com/42-2/issue-165-reviews/',
    },
    {
      quote:
        "Nyack School of Music alumnus Glauco Lima (NC ’14) has gotten a rave review on his new album, Once Upon a Time.",
      author: 'Nyack College',
      url: 'https://www.nyack.edu/news/rave-review-for-new-album-by-nyack-nyc-alumnus-glauco-lima/',
    },
  ],
};

const renderSource = (source, url) => {
  if (url) {
    if (source) {
      return (
        <>
          <b>,</b>
          <p>
            <a href={url} target='_blank' rel='noopener noreferrer'>
              {source}
            </a>
          </p>
        </>
      )
    } else {
      return (
        <p>
          <a href={url} target='_blank' rel='noopener noreferrer'>
            Source
          </a>
        </p>
      )
    }
  }
}

export default function DiscographyReviews() {
  return (
    <Container className="discography-reviews">
      <h1 className='title oswald'>{discographyInfo.title} Reviews</h1>
      <Carousel interval={null}>
        {discographyInfo.reviews.map((review, i) => (
          <Carousel.Item key={i}>
            <div className='review text-center'>
              <p className="font-italic quote">{review.quote}</p>
              <div className="citation">
                <b>{review.author}</b>
                {renderSource(review.source, review.url)}
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
}