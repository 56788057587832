// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from 'firebase/app';
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	getFirestore,
	orderBy,
	query,
	setDoc,
} from 'firebase/firestore';
import {
	GoogleAuthProvider,
	browserLocalPersistence,
	getAuth,
	setPersistence,
	signInWithPopup,
} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyBmDlDbf4iQIH_XtiYhvWSqK1uOIR58b34',
	authDomain: 'glauco-lima-website.firebaseapp.com',
	databaseURL: 'https://glauco-lima-website.firebaseio.com',
	projectId: 'glauco-lima-website',
	storageBucket: 'glauco-lima-website.appspot.com',
	messagingSenderId: '710237531972',
	appId: '1:710237531972:web:216bef600d220ac7b73111',
	measurementId: 'G-QKYHG78S3G',
};

// Initialize Firebase
const app = !getApps.length ? initializeApp(firebaseConfig) : getApp();
const db = getFirestore(app);
export const fb_auth = getAuth(app);

export const newTimelineRef = doc(collection(db, 'timeline_updates'));

export const timelineRef = collection(db, 'timeline_updates');

export const createTimelinePost = async ({ text }) => {
	const post = newTimelineRef;
	await setDoc(post, { id: post.id, text: text, timestamp: Date.now() });
};

export const getTimelinePosts = async () => {
	const q = query(timelineRef, orderBy('timestamp', 'desc'));
	const posts = await getDocs(q);

	return posts.docs;
};

export const deleteTimelinePost = async ({ id }) => {
	await deleteDoc(doc(db, 'timeline_updates', id));
};

const googleProvider = new GoogleAuthProvider();

export const signUpWithGoogle = async () => {
	googleProvider.setCustomParameters({
		prompt: 'select_account',
	});
	setPersistence(fb_auth, browserLocalPersistence).then(() => {
		//console.log("State is now persisted");
	});
	try {
		const res = await signInWithPopup(fb_auth, googleProvider);
		const { displayName, photoURL, email, uid, accessToken, refreshToken } = res.user;
		return {
			displayName,
			photoURL,
			email,
			uid,
			accessToken,
			refreshToken,
		};
	} catch (err) {
		console.error(err.message);
		return { error: true };
	}
};
