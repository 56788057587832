import React from 'react';
import { Container, Image } from 'react-bootstrap';
import HeroImage from '../res/images/online-music-course.jpeg';

export default function MusicCourses() {
  return (
    <Container className="online-music-courses">
      <div className="page-heading">Music Courses</div>
      <Image src={HeroImage} fluid />
      <div className="title">
        All-Level Improvisation Course Coming Soon!
      </div>
    </Container>
  )
}