import React from 'react';
import Hero from './Hero';
import Fade from 'react-reveal/Fade';
import { Col, Container, Image, Row } from 'react-bootstrap';
import homepageData from '../constants/home';

export default function Home(props) {
  return (
    <React.Fragment>
      <Fade>
        <div className='hero-container'>
          <Hero />
          <section className='text-white centered'>
            <h1 className='text-center hero-name oswald'>GLAUCO LIMA</h1>
            <p className='hero-text text-center'>
              Welcome to my website!
              <br /> Thanks for visiting and God bless you,
              <br /><span className="font-italic">— Glauco Lima</span>
            </p>
          </section>
        </div>
      </Fade>
      <section id='bio' name='bio'>
        <h1 className='page-heading'>Biography</h1>
        <Container className='bio-container'>
          {homepageData.map((bio, i) => (
            <Row key={i} className="bio-section">
              <Col lg={6} className="bio-info">
                <h3 className="text-center">{bio.title}</h3>
                <hr />
                <p className='text-justify bio-text'>
                  {bio.content}
                </p>
              </Col>
              <Col lg={6} className="bio-image">
                <section className='polaroid'>
                  <Image src={bio.image} alt={bio.alt} />
                </section>
              </Col>
            </Row>
          ))}
        </Container>
        <Container className='bio-container reviews-and-collabs'>
          <Row className="bio-section">
            <Col lg={{ span: 5, offset: 1 }} className="bio-info text-center">
              <h3>Collaborations And Work</h3>
              <hr />
              <p className='text-justify bio-text'>
                The following are some of the artists and musicians Mr. Lima has worked with: John
                Patitucci, Doug Epstein, Todd Coolman, René Calvin, Edward Perez, Romina Capitani,
                James Czeiner, Alvin Slaughter, Ron Kenoly, Abraham Laboriel, Dennis Mackrel, Javon
                Jackson, Billy Drummond, Antonio Hart, Rogerio Boccato, Zé Luis, Alexandre Aposan,
                Derico Sciotti, Paulo Braga, Eduardo Belo, João Alexandre, Paulo Baruk, Trazendo a
                Arca, Fernandinho, Davi Sacer, Luis Arcanjo, Gary Haase, Peter Calandra, Mary
                Gatchell, Buddy Williams, Paulo Siqueira, Dennis Bulhões, Itaiguara Brandão, Cyro
                Baptista, Janet Grice, Dave Darlington, James Walsh, and many more.
                <br />
                Glauco Lima is constantly searching for new possibilities, new sonorities, and ways
                of expressing art through music. This concept led him to create his last album
                entitled Once Upon a Time. It's an instrumental project consisted mostly of original
                compositions. The album also features a story written by him in support of
                environmental conservancy that develops along with the songs. This album received
                some rave reviews from acclaimed institutions.
              </p>
            </Col>
            <Col lg={{ span: 4, offset: 1 }} className='text-center font-italic review'>
              <h4>Reviews for Mr. Lima's album</h4>
              <p>
                <br />
                “Lima's fantastic ability to entrance the listener through multiple styles and his
                piano playing, full of dynamics displays a rich musical maturity.” <br />–
                AllAboutJazz
              </p>
              <p>
                “The energy and beauty that comes from a Latin-Jazz crossover projects is always a
                treat, especially in the hands (literally) of a master pianist such as Glauco Lima.”{' '}
                <br />– JazzTimes
              </p>
              <p>
                “The result a sound that is at once jazz and Brazilian and the combination is
                infectous! And oh the sound… it is an enjoyable and worth-hearing experience with
                lots of interesting nuances and creativity” <br />– Grady Harp – amazon.com – Hall
                of Fame Top 100 Reviewer Vice Voice
              </p>
              <p>
                “The harmonic forms which they scroll have an elegant choreography and a flawless
                luster. Once Upon a Time expresses his penchant for creativity and experience in the
                contemporary jazz forum” <br />– Susan Frances – AXS Los Angeles
              </p>
              <hr />
              <p>
                Glauco strongly believes that the closer we are to music the closer we are to God!
              </p>

            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
