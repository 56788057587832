import React from 'react';
import Fade from 'react-reveal/Fade';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import DiscographyReviews from './DiscographyReviews';

import ChristmasOnPianoCover from '../res/images/Album_Covers/Christmas on Piano_front cover.jpg';
import OnceUponATimeCover from '../res/images/Album_Covers/Once Upon a Time_front cover.jpg';
import SevenkCover from '../res/images/Album_Covers/Sevenk_final-cover_rev-01.png';
import { ShopModal } from './ShopModal';


let discography = [
  {
    id: 'sevenk',
    title: 'Sevenk',
    img: SevenkCover,
    year: '2022',
    feat: 'Glauco Lima Trio ft. João Mota & Michael PipoQuinha',
    isShopAvailable: true,
  },
  {
    id: 'christmasOnPiano',
    title: 'Christmas On Piano',
    img: ChristmasOnPianoCover,
    year: '2011',
    feat: 'Glauco Lima on piano',
    isShopAvailable: true,
  },
  {
    id: 'onceUponATime',
    title: 'Once Upon A Time',
    img: OnceUponATimeCover,
    year: '2015',
    feat: 'Special Guest – John Patitucci, Glauco Lima – piano, Eduardo Belo – bass, Marcio Silva – bass, João Mota – drums, Cliff Ribeiro – drums, Rogério Boccato – percussion, Lívio Almeida – saxophone and flute',
    isShopAvailable: true,
  },
];

export default function DiscographyComponent() {
  const [modalShow, setModalShow] = React.useState('');

  const DiscoCard = (props) => {
    return (
      <div className='album'>
        <h4>{props.title}</h4>
        <Image width={300} height={300} src={props.img} alt='album cover' />
        {props.year && (
          <p>
            <b>Year:</b> {props.year}
          </p>
        )}
        <p>
          <b>Featuring:</b> {props.feat}
        </p>
        {props.isShopAvailable && (
          <>
            <Button className="primary-button" onClick={() => setModalShow(`modal-${props.album_id}`)}>
              Shop
            </Button>

            {modalShow === `modal-${props.album_id}` && (
              <ShopModal
                id={`modal-${props.album_id}`}
                show={modalShow === `modal-${props.album_id}`}
                onHide={() => setModalShow(false)}
                musictitle={props.title}
                album_id={props.album_id}
              />
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className='discography'>
      <Fade>
        <Container>
          <h1 className='page-heading oswald'>Discography</h1>
        </Container>
        <section className='discs'>
          <Row>
            {discography.map((disc, i) => (
              <Col key={i} className='disc' xs={12} md={6} lg={4}>
                <DiscoCard
                  key={i}
                  id={`card-${i}`}
                  title={disc.title}
                  album_id={disc.id}
                  img={disc.img}
                  year={disc.year}
                  feat={disc.feat}
                  isShopAvailable={disc.isShopAvailable}
                />
              </Col>
            ))}
          </Row>
        </section>
      </Fade>
      <Fade>
        <DiscographyReviews />
      </Fade>
    </div>
  );
}
