import React from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function BlogComponent() {
  const [posts, setPosts] = React.useState([]);
  React.useEffect(() => {
    const fetchBlogList = async () => {
      axios
        .get('https://glauco-lima-backend.herokuapp.com/blog')
        .then((response) => {
          console.log(response);
          setPosts(response.data);
        })
        .catch((error) => {
          throw error;
        });
    };

    fetchBlogList();
  }, [setPosts]);

  return (
    <div className='blog'>
      <Fade>
        <h1 className='page-heading oswald blog-title'>Blog</h1>
      </Fade>
      <Fade>
        <div className='blog-posts'>
          {posts.length > 0 &&
            posts.map((post) => (
              <Container key={post._id}>
                <Link to={`/blog/${post.slug}`}>
                  <h2 className='blog-title'>{post.title}</h2>
                </Link>
                <p className='blog-description'>{post.shortDescription}</p>
              </Container>
            ))}
        </div>
      </Fade>
    </div>
  );
}
