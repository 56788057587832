import guitar from '../res/images/guitar.jpeg';
import bass from '../res/images/bass-min.jpg';
import eng from '../res/images/eng-min.jpg';
import orchestra from '../res/images/orchestra-min.jpg';
import drum from '../res/images/drum-min.jpg';
import singer from '../res/images/singer-min.jpg';
import wind from '../res/images/wind-min.jpg';

const artistsInfo = [
  {
    title: 'Wind Instrumentalists',
    artists: `Javon Jackson, Antonio Hart, Derico Sciotti, Zé Luís, Paulo Siqueira,
    Janet Grice, Lívio Almeida, Gerson Galante, Alejandro Aviles, Marcos D’Aloia,
    Oliver Santana, Gilton Martinez, Peter Holsberg, Hendrik Meurkens, Sue Talley,
    Abel Meireles, Wander Peixoto, among others.`,
    image: wind,
    alt: 'wind',
  },
  {
    title: 'Singers',
    artists: `Ron Kenoly, Alvin Slaughter, Dana Talley, Mary Gatchell, Shari Pine,
    Misha Steinhauer, Gus Sinaro, Davi Sacer, Luis Arcanjo, Paulo Baruk, Jeanne Mascarenhas,
    Romina Capitani, Wander Peixoto, among others.`,
    image: singer,
    alt: 'singers',
  },
  {
    title: 'Guitarists',
    artists: `Lars Frandsen, Peter Bernstein, Itaiguara Brandão, Elizeu Menezes,
    Wesley Amorim, Gus Sinaro, Cris Brivilier, Flávio Silva, Luís Fernandes (estilingue),
    Issac Ramos, Carlos Cuestas, among others.`,
    image: guitar,
    alt: 'guitarists',
  },
  {
    title: 'Drummers / Percussionists',
    artists: `Márcio Bahia, Ramon Montagner, Dennis Bulhões, Alexandre Aposan,
    Buddy Williams, Kenny Grohowski, Dennis Mackrel, Mauricio Zotarelli, Samvel Sarkisyan,
    Hendrik Meurkens, Adriano Santos, Andrew Pastorino, Mauricio de Souza, João Mota,
    Cliff Ribeiro, Eduardo Moura, Alex Kautz, Victor Ladoano, among others.`,
    image: drum,
    alt: 'drummers',
  },
  {
    title: 'Bassists',
    artists: `John Patitucci, Abraham Laboriel, Itaiguara Brandão, Michael Pipoquinha,
    Eduardo Belo, Gustavo Amarante, Felipe Brisola, Fábio Aposan, Dave Edwards,
    Steve Doyle, Eddy Khaimovich, Fernando Lima, Renan Meira Goudard (Ray), among others.`,
    image: bass,
    alt: 'bassists',
  },
  {
    title: 'Sound Engineers',
    artists: `Doug Epstein, Dave Darlington, James Czeiner, James Walsh, Cliff Ribeiro, among others.`,
    image: eng,
    alt: 'sound engineers',
  },
  {
    title: 'Conductors',
    artists: `Parcival Módolo, Elizabeth Swanson, Sue Talley, Damien Sneed,
    Darryl Jordan, Rafael Piccolotto de Lima`,
    image: orchestra,
    alt: 'orchestra',
  },
];

export default artistsInfo;
