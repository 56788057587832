import React from 'react';
import SevenkImage from '../res/images/Album_Covers/Sevenk_final-cover_rev-01.png';
import LatestReleaseCard from '../components/LatestRelease';
import TimelineUpdatesList from '../components/TimelineUpdatesList';

function LatestUpdates() {
	const latest_release = {
		id: 'sevenk',
		name: 'Sevenk',
		icon: SevenkImage,
		year: 2022,
		isShopAvailable: true,
		description: `This new arrangement I wrote for my original composition Sevenk was
      originally intended for a collab video. However, after hearing the final
      result I decided to release it as an official Single. I'm proud to have on board
      the great Michael Pipoquinha and João Mota. It was released on March 2022.
      I hope you enjoy it and be blessed!`,
	};
	return (
		<div>
			<h2 className='page-heading'>Latest Updates</h2>
			<div className='Page-grid'>
				<TimelineUpdatesList />
			</div>

			<LatestReleaseCard
				id={latest_release.id}
				name={latest_release.name}
				image={latest_release.icon}
				year={latest_release.year}
				is_shop_available={latest_release.isShopAvailable}
				description={latest_release.description}
				title='Sevenk 2022'
			/>
		</div>
	);
}

export default LatestUpdates;
