import React from 'react';
import { useEffect } from 'react';
import TimelineUpdateCard from './TimelineUpdateCard';
import { useState } from 'react';
import { getTimelinePosts } from '../fb';

function TimelineUpdatesList({ edit = false }) {
	const [isLoading, setLoading] = useState(true);
	const [timeline, setTimeline] = useState([
		/* {
			text: 'This is an example timeline update. This can be long form or short form text depending on the user timeline',
			timestamp: Date.now(),
		},
		{
			text: 'This is an example timeline update. This can be long form or short form text depending on the user timelineThis is an example timeline update. This can be long form or short form text depending on the user timeline',
			timestamp: Date.now(),
		},
		{
			text: 'This is an example timeline update. This can be long form or short form text depending on the user timelineThis is an example timeline update. This can be long form or short form text depending on the user timeline',
			timestamp: Date.now(),
		}, */
	]);
	useEffect(() => {
		console.log('rendering');
		if (timeline.length === 0)
			getTimelinePosts().then((postSnaps) => {
				if (postSnaps.length === 0) {
					setTimeline([false]);
					setLoading(false);
				} else {
					setTimeline(postSnaps);
					setLoading(false);
				}
			});
	}, []);
	if (isLoading) return <p>Loading Updates...</p>;
	return (
		<>
			{timeline.map((updateSnap, index) => {
				if (!updateSnap) return null;
				else {
					const update = updateSnap.data();
					return (
						<TimelineUpdateCard
							key={index}
							id={update.id}
							text={update.text}
							timestamp={update.timestamp}
							edit={edit}
						/>
					);
				}
			})}
		</>
	);
}

export default TimelineUpdatesList;
