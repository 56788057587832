import React from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import shopMusic from '../constants/discography';

export function ShopModal(props) {
  return (
    <Modal className="shop-modal"
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id={`modal-${props.album_id}`}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Shop {props.musictitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul style={{ padding: 0 }}>
          {shopMusic[props.album_id].links.map((link, index) => {
            return (
              <li key={index} className="shop-music" style={{ borderBottom: ".25px dashed", padding: ".3em 0" }}>
                <a href={link.url}
                  target="_blank" rel="noopener noreferrer"
                  style={{ width: "100%", display: "block" }}>
                  {link.logo && <Image height={32} width={32} src={link.logo} thumbnail style={{ marginRight: "5px" }} />}
                  <span>{link.title}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}