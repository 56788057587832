import React from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import { Fade } from 'react-reveal';
import HeroImage from '../res/images/Music-and-society.jpg';

const ytVideoDetails = [
	{
		title: 'YouTube video player - Música e Sociedade: a arte de fazer o bem',
		url: 'https://www.youtube.com/embed/hIQ9g1oUcZk',
	},
	{
		title:
			'YouTube video player - A PAZ (Heal the World - Michael Jackson) Musica e Sociedade, a Arte de Fazer o Bem',
		url: 'https://www.youtube.com/embed/rMWhht7Xj8k',
	},
];

const knowMoreDetails = [
	{
		title: 'Facebook',
		url: 'https://www.facebook.com/musicaesociedademarilia/?ref=page_internal',
	},
	{
		title: 'Instagram',
		url: 'https://www.instagram.com/musicaesociedademarilia/',
	},
	{
		title: 'YouTube',
		url: 'https://www.youtube.com/channel/UCk7tJfaYMKSu-W-viasPPzA',
	},
];

export default function MusicAndSociety() {
	return (
		<Fade>
			<Container className='music-and-society'>
				<h1 className='page-heading oswald'>Music and Society, The Art of Doing Good</h1>
				<p className='oswald title spanish-title'>- Musica e Sociedade, a Arte de Fazer o Bem</p>

				<div className='hero-image'>
					<Image src={HeroImage} fluid />
				</div>
				<div className='text-container'>
					<p>
						Music and Society, The Art of Doing Good is an annual event held in Marilia, São Paulo,
						Brazil, which aims to promote quality music and foster art in general through a
						combination of promoting local artists and raising funds and/or goods for local
						organizations, associations, or social entities. The event is an open platform available
						to all local artists where they can present their talents and work in an appropriate and
						effective manner. All funds or goods raised at each event are fully donated to the
						society of Marilia town through donations to local organizations, associations, or
						social entities. Every year Music and Society invites an acclaimed national or
						international artist to feature along with local artists. The last edition of Music and
						Society had the privilege of having the participation of the brilliant musician Derico
						Sciotti (saxophonist of Programa do Jô). Music and Society promotes a gathering among
						local artists and nationally/internationally acclaimed artists as well as an inclusion
						of less expressive artists, adding solidarity and love for others.
					</p>
				</div>
				<Row className='youtube-video-container'>
					{ytVideoDetails.map((video, i) => (
						<Col className='youtube-video' key={i} lg={6}>
							<iframe
								height='315'
								src={video.url}
								title={video.title}
								frameborder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowfullscreen='allowFullScreen'
							/>
						</Col>
					))}
				</Row>
				<Row className='text-center know-more'>
					<Col className='text-center'>
						Know more about Music and Society, The Art of Doing Good:
						<p>
							{knowMoreDetails.map((detail, index) => (
								<>
									{index ? ' | ' : ''}
									<a key={index} href={detail.url} target='_blank' rel='noopener noreferrer'>
										{detail.title}
									</a>
								</>
							))}
						</p>
					</Col>
				</Row>
			</Container>
		</Fade>
	);
}
