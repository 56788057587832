import React, { Fragment } from 'react';
import { Routes, Route, Link } from 'react-router-dom';

import HomeComponent from './components/HomeComponent';
import NavigationBar from './components/NavigationBar';
import navLinks from './pages/navbar-links';

import FooterComponent from './components/FooterComponent';
import DiscographyComponent from './components/DiscographyComponent';
import ArtistComponent from './components/ArtistComponent';
import ProjectComponent from './components/ProjectComponent';
import MusicAndSociety from './components/MusicAndSociety';
import PressLinks from './components/PressLinks';
import MusicCourses from './components/MusicCourses';
import BlogComponent from './components/Blog';
import BlogDetailComponent from './components/BlogDetail';
import LatestUpdates from './pages/LatestUpdates';
import MakeTimelinePost from './pages/MakeTimelinePost';
/* import LatestUpdates from './components/LatestUpdates'; */

function HeaderHome() {
	return (
		<div className='position-absolute w-100'>
			<header className='header-home roboto'>
				<Link to='/' className='font-light'>
					GLAUCO LIMA
				</Link>
				<NavigationBar navLinks={navLinks} />
			</header>
		</div>
	);
}

function Header() {
	return (
		<Fragment>
			<header className='header roboto'>
				<section className='font-light'>
					<Link to='/'>GLAUCO LIMA</Link>
				</section>
				<NavigationBar navLinks={navLinks} />
			</header>
			<hr></hr>
		</Fragment>
	);
}

function App() {
	return (
		<div className='App'>
			<Routes primary={false}>
				<Route path='/' element={<HeaderHome />} />
				<Route path='/:any' element={<Header />} />
				<Route path='/:any/:any' element={<Header />} />
			</Routes>
			<Routes>
				<Route path='/' element={<HomeComponent />} />
				<Route path='/blog' element={<BlogComponent />} />
				<Route path='blog'>
					<Route path=':slug' element={<BlogDetailComponent />} />
				</Route>
				<Route path='/discography' element={<DiscographyComponent />} />
				<Route path='/artists' element={<ArtistComponent />} />
				<Route path='/projects' element={<ProjectComponent />} />
				<Route path='/music-and-society' element={<MusicAndSociety />} />
				<Route path='/press-links' element={<PressLinks />} />
				<Route path='/music-courses' element={<MusicCourses />} />
				<Route path='/latest-updates' element={<LatestUpdates />} />
				<Route path='/timeline' element={<MakeTimelinePost />} />
			</Routes>
			<FooterComponent />
		</div>
	);
}

export default App;
