import React from 'react';
import glauco_avatar from '../res/images/glauco_avatar.jpg';
import { deleteTimelinePost } from '../fb';

function TimelineUpdateCard({ id, image, text, timestamp, children, edit = false }) {
	const publish_date = new Date(timestamp);
	const make_timestamp =
		publish_date.getDate() + '/' + (publish_date.getMonth() + 1) + '/' + publish_date.getFullYear();

	const handleDelete = (id) => {
		deleteTimelinePost({ id: id }).then(() => {
			window.location.reload();
		});
	};
	return (
		<div className='Timeline-card-container'>
			<div className='Timeline-user-header'>
				<img src={glauco_avatar} alt='glauco avatar' />
				<p>Glauco Lima</p>
			</div>
			{children}
			{image ? (
				<div className='Timeline-card-image'>
					<img src={image} alt='timeline update' />
				</div>
			) : null}
			{text ? (
				<p className='Timeline-card-description'>
					{text}
					<br />
				</p>
			) : null}

			{timestamp ? (
				<p
					style={{
						marginTop: '8px',
						fontSize: '14px',
						color: 'rgba(0,0,0,0.5)',
						fontWeight: '600',
					}}
					className='Timeline-card-timestamp'>
					{make_timestamp}
					{edit ? (
						<span
							onClick={() => {
								handleDelete(id);
							}}
							style={{ float: 'right', color: 'red', cursor: 'pointer' }}>
							Delete
						</span>
					) : null}
				</p>
			) : null}
		</div>
	);
}

export default TimelineUpdateCard;
