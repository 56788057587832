import React from 'react';
import { ShopModal } from './ShopModal';
import { useState } from 'react';
function LatestReleaseCard({
	id = '',
	title = '',
	name = '',
	description = '',
	image = '',
	is_shop_available = true,
	year = '2023',
}) {
	const [modalShow, setModalShow] = useState(false);
	return (
		<div className='Latest-release-card-container'>
			<div className='Latest-release-card'>
				<div className='Latest-release-card-image'>
					<img style={{ width: '100%' }} src={image} alt='cover' />
				</div>
				<p className='Latest-release-card-title'>{title}</p>
				<p className='Latest-release-card-description'>{description}</p>
				{is_shop_available ? (
					<button onClick={() => setModalShow(`modal-${id}`)} className='primary-button'>
						Shop Now
					</button>
				) : null}
				{modalShow === `modal-${id}` && (
					<ShopModal
						id={`modal-${id}`}
						show={modalShow === `modal-${id}`}
						onHide={() => setModalShow(false)}
						musictitle={name}
						album_id={id}
					/>
				)}
			</div>
		</div>
	);
}

export default LatestReleaseCard;
