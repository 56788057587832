const pressLinksDetails = [
  {
    title: `Pianista mariliense radicado nos EUA lança novo jingle instrumental "Sevenk"`,
    url: 'https://www.giromarilia.com.br/noticia/giro-marilia/pianista-mariliense-radicado-nos-eua-lanca-novo-jingle-instrumental-sevenk/90310',
    source: 'Giro Marília',
  },
  {
    title: 'GLAUCO LIMA PIANO QUARTET!',
    url: 'https://www.shanghaijazz.com/listen/2018/11/15/glauco-lima-piano-trio',
    source: 'Shanghai JAZZ',
  },
  {
    title: 'BRAZILIAN & LATIN JAZZ INFUSIONS FT. GLAUCO LIMA QUARTET!',
    url: 'https://www.shanghaijazz.com/listen/2019/1/31/brazilian-amp-latin-jazz-infusions-ft-glauco-lima-quartet',
    source: 'Shanghai JAZZ',
  },
  {
    title: 'SOM DO BRASIL PLAYLIST FOR 10/12/2016',
    url: 'https://www.cc-seas.columbia.edu/wkcr/node/18694',
    source: 'WKCR 89.9 FM',
  },
  {
    title: 'Jazz Education Network',
    url: 'https://www.eventscribe.com/2020/JEN/fsPopup.asp?efp=UFdTUUJZQlc4MjE2&PresenterID=834427&rnd=6.779301E-02&mode=presenterinfo',
    source: 'EventScribe',
  },
  {
    title: 'Michaela Steinhauer with Glauco Lima and Samuel Martinelle',
    url: 'http://sunnysidenyc.rcachurches.org/jazz-performance.html',
    source: 'Sunnyside Reformed Church',
  },
  {
    title: 'Editorial Stock Photo',
    url: 'https://www.shutterstock.com/editorial/image-editorial/nyc-street-pianos-new-york-usa-05-jun-2017-8858288b',
    source: 'ShutterStock',
  },
  {
    title: 'Concerts Presented May 2017',
    url: 'https://qcpages.qc.cuny.edu/music/concerts/2017/may',
    source: 'Aaron Copland School of Music',
  },
  {
    title: 'Janet Grice and Paulo Siqueira: Proesas For Bassoon (Janet Grice)',
    url: 'https://www.jazziz.com/new-releases/janet-grice-and-paulo-siqueira-proesas-for-bassoon/',
    source: 'JAZZIZ',
  },
  {
    title: 'GIRA COMIGO - Entrevista com Derico Sciotti, Glauco Lima e Adriano Martins',
    url: 'https://www.giromarilia.com.br/noticia/gira-comigo-na-tv/gira-comigo-entrevista-com-derico-sciotti-glauco-lima-e-adriano-martins/16382',
    source: 'Giro Marília',
  },
  {
    title: 'Glauco Lima lança disco "Christmas on Piano" em Campinas',
    url: 'https://www.diariodetatui.com/2012/01/glauco-lima-lanca-disco-christmas-on.html',
    source: 'Diário de Tatuí',
  },
  {
    title: 'Glauco Lima - Turismo João Pessoa',
    url: 'https://turismo.joaopessoa.pb.gov.br/agenda-cultural/glauco-lima/',
    source: 'Turismo João Pessoa',
  },
  {
    title: 'Pianista Glauco Lima',
    url: 'https://portalcorreio.com.br/t/pianista-glauco-lima/',
    source: 'Portal Correio',
  },
  {
    title: 'Marília recebe evento “Música e Sociedade: a Arte de Fazer o Bem”',
    url: 'https://14news.com.br/regiao/marilia-recebe-evento-musica-e-sociedade-a-arte-de-fazer-o-bem/',
    source: '14 News',
  },
  {
    title:
      'Teatro Municipal recebe 3ª edição do evento “Música e Sociedade: a Arte de Fazer o Bem”',
    url: 'https://www.marilia.sp.gov.br/portal/noticias/0/3/6054/teatro-municipal-recebe-3-edicao-do-evento--musica-e-sociedade-a-arte-de-fazer-o-bem',
    source: 'Prefeitura de Marília',
  },
  {
    url: 'http://www.jornaldamanhamarilia.com.br/exibe.php?id=17136',
    source: 'JORNAL DA MANHÃ',
  },
  {
    title:
      'Marília recebe 3ª ed. de “Música e Sociedade: a Arte de Fazer o Bem” com participação especial do saxofonista Derico',
    url: 'https://www.revistad.com.br/arquivo_d/marilia-recebe-3a-ed-de-musica-e-sociedade-a-arte-de-fazer-o-bem-com-participacao-especial-do-saxofonista-derico/',
    source: 'Marília',
  },
  {
    title: `'Música e Sociedade' chega a 3ª edição com participação de Derico | Visão Notícias - Informações de Marília e região`,
    url: 'https://www.visaonoticias.com/noticia/37793/musica-e-sociedade-chega-a-3-edicao-com-participacao-de-derico',
    source: 'Visão Notícias',
  },
  {
    title: `Reconhecimento: mariliense é o novo diretor musical de show da Broadway! | Visão Notícias - Informações de Marília e região`,
    url: 'https://www.visaonoticias.com/noticia/34503/reconhecimento-mariliense-e-o-novo-diretor-musical-de-show-da-broadway',
    source: 'Visão Notícias',
  },
  {
    url: 'https://www.jornaldopovomarilia.net/single-post/2018/11/22/Músico-mariliense-é-escolhido-como-diretor-musical-de-show-da-Broadway-nos-EUA',
    source: 'Jornal do Povo',
  },
  {
    title:
      'Mariliense dirige show na Broadway e estuda projetos na cidade - Notícias sobre variedades - Guia Giro - Giro Marília Notícias - Mariliense dirige show na Broadway e estuda projetos na cidade',
    url: 'https://www.giromarilia.com.br/guia-giro/secao/variedades/noticia/mariliense-dirige-show-na-broadway-e-estuda-projetos-na-cidade/13951',
    source: 'Giro Marília',
  },
  {
    title:
      'Pianista de Marília dirigiu espetáculo musical na Broadway “foi um desafio e aprendi muito a cada ensaio” - Solutudo',
    url: 'https://conteudo.solutudo.com.br/solutudo/pianista-de-marilia-dirigiu-espetaculo-musical-na-broadway-foi-um-desafio-e-aprendi-muito-a-cada-ensaio/',
    source: 'Solutudo',
  },
];

export default pressLinksDetails;