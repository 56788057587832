import town from '../res/images/town.jpg';
import edu from '../res/images/edu.jpg';
import industry from '../res/images/industry.jpg';
import career from '../res/images/career.jpg';

const homepageData = [
  {
    title: 'Growing Up And Hometown',
    content: `Natural of Marília, a town of São Paulo state in Brazil, Glauco Lima’s unique
    musical talent was clear since his very young age, when he used to hum along
    with his mother Nair on her lullabies even before speaking, says her.He began
    playing acoustic guitar when he was five years old by watching his grandpa
    playing folk songs.His passion for piano flourished when he was eight.He
    explains that something extraordinary happened at the very moment he first
    touched the piano as if he was touching a power source! Glauco naturally
    transferred all his self- taugh knowledge from guitar to piano.As a young boy
    dreaming about the world of music, arts, and productions, he couldn’t even
    have a glimpse of imagination that one day he would be living the dream to be
    part of the musical scene of New York City!`,
    image: town,
    alt: 'town',
  },
  {
    title: 'Education And Early Experience',
    content: `In support to his unique talent, his parents enrolled him on a traditional
    nine-years classical piano course to study with Francisco de Paula Souza – Chico –
    at the prestigious local Conservatory of Music “Carlos Gomes”, where he earned a
    diploma of Piano Technician. His parents also put together a wedding band to support
    their prodigal son. Soon, the young boy Glauco Lima became the bandleader. As the
    bandleader he used to learn his part as well as the others’ and teach them on top of
    leading rehearsals and singing. This was the official beginning of his professional
    career with fourteen years old. Soon after he was introduced to the jazz world by
    the acclaimed local pianist Claudio Borici better known as “Federal”, who became his
    mentor and one his best friends. Federal noticed his unique talent right away and
    brought him to study at the national acclaimed Conservatory of Music and Drama of
    Tatui, a town nearby São Paulo City, Brazil. At that time, the young Lima used to
    travel five hours to the conservatory, study the whole day, and then travel five
    hours back home. He was constantly called to tutor his colleagues from the
    conservatory. There, Glauco learned about all the “big names” in music such as Miles
    Davis, John Coltrane, Chick Corea, Egberto Gismonti, John Patitucci, Tom Jobim,
    Pixinguinha, Astor Piazolla, Abraham Laboriel, Milton Nascimento and also couldn’t
    even imagine that he would work with some of those later on.`,
    image: edu,
    alt: 'education',
  },
  {
    title: 'Work In The Music Industry',
    content: `After nine years of classical piano studies and six years of jazz piano studies
    Mr.Lima joined the evangelistic vocal band Klim Kideshim, invited by its leader
    Marcos Pereira. He traveled intensely with this band as a singer, vocal arranger,
    producer, and sound engineer. He also arranged, produced, and recorded one of their
    albums entitled Klim Vocal Band. After roughly fours years, the video production
    company LPC Comunicações hired Mr. Lima as the director of the Music Department. He
    had a very prolific time at this company working as a composer, producer, arranger,
    recording engineer, and director of several soundtracks for TV. One of their most
    distinguished productions was the Turma da Arca, a Kid’s TV Series of which he was
    the main composer, the producer, and the sound engineer.`,
    image: industry,
    alt: 'industry',
  },
  {
    title: 'His Diverse Career',
    content: `Glauco was constantly teaching privately on a high demand. During that time, he also
    was a baritone of the chorale of the Symphony Orchestra of Rio Claro, a nearby town.
    A couple of years later Mr.Lima moved to the United States of America to work as a
    Musical Director at The Good Shepherd Church in New Jersey. Currently Glauco Lima is
    the Musical Director of the Faith Exchange Fellowship Church in Manhattan, and a
    Professor of Music at Nyack College and Rider Univeristy. He holds a diploma of
    Technician of Piano from the Conservatory of Music Carlos Gomes, Marilia, Brazil; a
    diploma of Jazz Piano from the Conservatory of Tatui, Brazil; a diploma of Bachelor
    in Music from the Nyack College, New York City; and a diploma of Master in Music
    from the Queens College, City University of New York. Glauco Lima also won the first
    place of a Full Scholarship and earned a Jazz Performance Award from the Nyack
    College. Glauco Lima has now more than twenty five years of experience as a
    composer, live performer, studio musician and producer, bandleader, and singer, and
    performed at some highly prestigious venues such as The Lincoln Center for the
    Performing Arts in New York City, the Fondazione Siena Jazz - Italy (Accademia
    Nazionale del Jazz), Un Tubo Jazz Club - Italy, Shanghai Jazz Club – New Jersey,
    Blue Note Jazz New York, and many more.`,
    image: career,
    alt: 'career',
  }
]

export default homepageData;