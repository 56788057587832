import AmazonMusic from '../res/images/icons/amazon-music.png';
import AppleMusic from '../res/images/icons/apple-music.png';
import SoundCloud from '../res/images/icons/soundcloud.png';
import Deezer from '../res/images/icons/deezer.png';
import Tratore from '../res/images/icons/tratore.png';
import Spotify from '../res/images/icons/spotify.png';
import ReverbNation from '../res/images/icons/reverbnation.png';
import Anghami from '../res/images/icons/anghami.png';
import BarnesAndNoble from '../res/images/icons/barnes-and-noble.png';
import Walmart from '../res/images/icons/walmart.png';
import MusicLogo from '../res/images/icons/music-note.png'
import TidalLogo from '../res/images/icons/tidal.png';
import AmazonLogo from '../res/images/icons/amazon.png';

const shopMusic = {
  onceUponATime: {
    links: [
      {
        logo: AmazonLogo,
        title: 'Amazon',
        url: 'https://www.amazon.com/Once-Upon-Time-Glauco-Lima/dp/B0164IHTYG',
      },
      {
        logo: AppleMusic,
        title: 'Apple Music',
        url: 'https://music.apple.com/us/album/once-upon-a-time/1046675705',
      },
      {
        logo: SoundCloud,
        title: 'SoundCloud',
        url: 'https://soundcloud.com/glauco-lima-3',
      },
      {
        logo: Deezer,
        title: 'Deezer',
        url: 'https://www.deezer.com/us/album/11363930',
      },
      {
        logo: Tratore,
        title: 'Tratore',
        url: 'https://tratore.com.br/um_cd.php?id=8317',
      },
      {
        logo: Spotify,
        title: 'Spotify',
        url: 'https://open.spotify.com/artist/2z7CLfVQ1R2t8yZ8THOOuq',
      },
      {
        logo: ReverbNation,
        title: 'ReverbNation',
        url: 'https://www.reverbnation.com/glaucolima',
      },
      {
        logo: Anghami,
        title: 'Anghami',
        url: 'https://play.anghami.com/album/3451264',
      },
      {
        logo: MusicLogo,
        title: 'AllMusic',
        url: 'https://www.allmusic.com/album/once-upon-a-time-mw0002911198',
      },
      {
        logo: BarnesAndNoble,
        title: 'Barnes And Noble',
        url: 'https://www.barnesandnoble.com/w/once-upon-a-time-glauco-lima/29487172',
      },
      {
        logo: MusicLogo,
        title: '6roove',
        url: 'https://6roove.com/artists/2z7CLfVQ1R2t8yZ8THOOuq',
      },
      {
        logo: Walmart,
        title: 'Walmart',
        url: 'https://www.walmart.com/ip/Once-Upon-a-Time/772317537',
      },
    ],
  },
  sevenk: {
    links: [
      {
        logo: AppleMusic,
        title: 'Apple Music',
        url: 'https://music.apple.com/au/album/sevenk-feat-jo%2525C3%2525A3o-mota-michael-pipoquinha-single/1612114172',
      },
      {
        logo: AmazonLogo,
        title: 'Amazon',
        url: 'https://www.amazon.com/dp/B09TMFVB8T?tag=fndcmpgns-20'
      },
      {
        logo: AmazonMusic,
        title: 'Amazon Music',
        url: 'https://music.amazon.ca/artists/B001RRWPQM/glauco-lima'
      },
      {
        logo: MusicLogo,
        title: 'Qobuz',
        url: 'https://www.qobuz.com/es-es/album/sevenk-feat-joao-mota-michael-pipoquinha-glauco-lima/gotb5hwrmynac'
      },
    ],
  },
  christmasOnPiano: {
    links: [
      {
        logo: AppleMusic,
        title: 'Apple Music',
        url: 'https://music.apple.com/us/album/christmas-on-piano/492342545',
      },
      {
        logo: AmazonLogo,
        title: 'Amazon',
        url: 'https://www.amazon.com/Christmas-Piano-Glauco-Lima/dp/B006RB9V6U',
      },
      {
        logo: AmazonLogo,
        title: 'Amazon UK',
        url: 'https://www.amazon.co.uk/Christmas-Piano-Glauco-Lima/dp/B006RBJ8IG',
      },
      {
        logo: TidalLogo,
        title: 'Tidal',
        url: 'https://tidal.com/browse/artist/6622869',
      },
      {
        logo: MusicLogo,
        title: 'AllMusic',
        url: 'https://www.allmusic.com/album/christmas-on-piano-mw0002557192',
      },
    ]
  }
};

export default shopMusic;