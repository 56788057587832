import turmaImage from '../res/images/Album_Covers/Turma-da-Arca_front-cover.jpg';
import deniseImage from '../res/images/Album_Covers/Denise Borecki_front cover.jpg';
import edsonImage from '../res/images/Album_Covers/Edson Dávilla_front cover.jpg';
import aryDeImage from '../res/images/Album_Covers/Ary de Farias_E Foi Assim_front cover.jpg';
import aryDeDeusImage from '../res/images/Album_Covers/Ary de Farias_Anjos de Deus_front cover.jpg';
import fernandoImage from '../res/images/Album_Covers/Fernando Fé e Glauco Lima_front cover.jpg';
import mishaImage from '../res/images/Album_Covers/Misha Steinhauer.jpg';
import janetImage from '../res/images/Album_Covers/Janet Grice_front cover.jpg';
import robertoImage from '../res/images/Album_Covers/Roberio Alencar.jpg';
import musicaSociedadeImage from '../res/images/Album_Covers/Musica e Sociedade_main photo.jpg';
import glaucoPortfolioImage from '../res/images/Album_Covers/Glauco Lima Soundtrack Portfolio.jpg';
import klimKideshim from '../res/images/Album_Covers/Klim-Kideshim.jpeg';

import glaucoPortfolioMp3 from '../res/images/Media/GlaucoLima_Soundtrack_Portfolio_1.mp3';


export const workDetails = [
  {
    role: 'Composer / Producer / Arranger / Sound Designing / Sound Engineering',
    projects: [
      {
        name: 'Turma da Arca',
        year: 1998,
        icon: turmaImage,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=cYFs-ux56Wo',
          },
          {
            name: 'LPC',
            url: 'https://www.lojalpc.org.br/produtos/colecao-turma-da-arca-16-dvds/',
          },
        ]
      },
      {
        name: 'La Vida Ahora',
        year: 2013,
        additionalInfo: 'Background music and Soundtracks',
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=QiJ4qDXqzLc',
          },
        ]
      }
    ]
  },
  {
    role: 'Producer / Arranger / Sound Engineering',
    projects: [
      {
        name: 'Klim Kideshim',
        icon: klimKideshim,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=IUWJhukgvsE',
          },
        ]
      },
      {
        name: 'Glauco Lima Soundtrack Portfolio',
        icon: glaucoPortfolioImage,
        links: [
          {
            name: 'MP3',
            url: glaucoPortfolioMp3,
          },
        ]
      },
      {
        name: 'Roberio Alencar',
        year: 1999,
        icon: robertoImage,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/channel/UCpNJS0X7HfoYon3l6ukMWnA',
          },
        ]
      },
      {
        name: 'Denise Borecki',
        year: 2001,
        icon: deniseImage,
        links: [
          {
            name: 'AllMusic',
            url: 'https://www.allmusic.com/album/the-potters-hand-mw0001512083',
          },
        ]
      },
      {
        name: 'Edson Dávilla',
        year: 2009,
        icon: edsonImage,
      },
      {
        name: 'Ary de Farias "E Foi Assim"',
        year: 2010,
        icon: aryDeImage,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=Hb_ctN0nCW0',
          },
        ]
      },
      {
        name: 'Musica e Sociedade, a Arte de Fazer o Bem',
        year: 2017,
        icon: musicaSociedadeImage,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=rMWhht7Xj8k',
          },
        ]
      },
      {
        name: 'Ary de Farias "Anjos de Deus"',
        year: 2020,
        icon: aryDeDeusImage,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=9BmOjKbcJig',
          },
        ]
      },
    ]
  },
  {
    role: 'Sideman Recordings',
    projects: [
      {
        name: 'Fernando Fé',
        year: 2010,
        icon: fernandoImage,
        links: [
          {
            name: 'Pandora',
            url: 'https://www.pandora.com/artist/fernando-fe-and-glauco-lima/voz-piano-e-algo-mais/ALX22vq7pgv62k9',
          },
        ]
      },
      {
        name: 'Misha Steinhauer',
        year: 2017,
        icon: mishaImage,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=YK3xmfa2z0g',
          },
        ]
      },
      {
        name: 'Janet Grice',
        year: 2018,
        icon: janetImage,
        links: [
          {
            name: 'YouTube',
            url: 'https://www.youtube.com/watch?v=LcTmvsFqZoU&list=OLAK5uy_ne-4QYoJ1N6L61FARUEuQsPfnFK5yx00k&index=17',
          },
          {
            name: 'Amazon',
            url: 'https://www.amazon.com/Proezas-Bassoon-Janet-Grice/dp/B07VGTW8MY',
          }
        ]
      },
    ]
  },

]