import React from 'react';
import fb from '.././res/icons/fb.svg';
import ins from '.././res/icons/ins.svg';
import ln from '.././res/icons/ln.svg';
//import mail from '.././res/icons/mail.svg';
import yt from '.././res/icons/yt.svg';
import twt from '.././res/icons/twt.svg';
import spot from '.././res/icons/spot.svg';

let link_data = [
	{ icon: fb, link: 'https://www.facebook.com/glaucolimanyc/' },
	{ icon: ins, link: 'https://www.instagram.com/glaucoplima/' },
	{ icon: spot, link: 'https://open.spotify.com/artist/2z7CLfVQ1R2t8yZ8THOOuq' },
	{ icon: twt, link: 'https://twitter.com/glaucolima12' },
	{ icon: ln, link: 'https://www.linkedin.com/in/glauco-lima-498b594b' },
	{ icon: yt, link: 'https://www.youtube.com/channel/UCSP4WkDY1vhV3nyHrmYP6Hg' },
];

function FooterComponent() {
	return (
		<div className='sm-links'>
			{link_data.map((data, i) => (
				<span key={i} className='sm-icon'>
					<a rel='noopener noreferrer' target='_blank' href={data.link}>
						<img alt='icon' width='45' src={data.icon}></img>
					</a>
				</span>
			))}
			<div>
				<p>
					<br></br>
					<br></br>© Glauco Lima. All rights reserved.
				</p>
			</div>
		</div>
		/*<section className="footer">
      <Container className="d-flex">
        <Row className="align-items-center">
          <Col md={6}>
            <div className="copyright">
              <span>&copy; Beauties of Eden 2019. All rights reserved.</span>
            </div>
          </Col>
          <Col md={6}>
            <div className="footer-links">
              <Breadcrumb>
                <li className="breadcrumb-item">
                  <Link to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/blog">
                    Blog
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/donate">
                    Donate
                  </Link>
                </li>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </Container>
    </section>*/
	);
}

export default FooterComponent;
