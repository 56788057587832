import React from 'react';
import LatestUpdates from './LatestUpdates';
import { useState } from 'react';
import { Form, FormGroup, InputGroup } from 'react-bootstrap';
import { createTimelinePost, fb_auth } from '../fb';
import TimelineUpdateCard from '../components/TimelineUpdateCard';
import TimelineUpdatesList from '../components/TimelineUpdatesList';
import SevenkImage from '../res/images/Album_Covers/Sevenk_final-cover_rev-01.png';
import LatestReleaseCard from '../components/LatestRelease';
import { useEffect } from 'react';
/* import QuillEditor from '../components/QuillEditor';
import { useQuill } from 'react-quilljs'; */
function MakeTimelinePost() {
	const [appAuth, setAppAuth] = useState('loading');
	useEffect(() => {
		fb_auth.onAuthStateChanged((user) => {
			if (user) {
				if (user.email === 'info@glaucolima.com') setAppAuth(true);
				else fb_auth.signOut();
			}
		});
	});

	const latest_release = {
		id: 'sevenk',
		name: 'Sevenk',
		icon: SevenkImage,
		year: 2022,
		isShopAvailable: true,
		description: `This new arrangement I wrote for my original composition Sevenk was
      originally intended for a collab video. However, after hearing the final
      result I decided to release it as an official Single. I'm proud to have on board
      the great Michael Pipoquinha and João Mota. It was released on March 2022.
      I hope you enjoy it and be blessed!`,
	};
	const onSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const formtext = Object.fromEntries(formData.entries()).text;
		//console.log(Object.fromEntries(formData.entries()));
		createTimelinePost({ text: formtext }).then(() => {
			window.location.reload();
		});
		console.log(formtext);
	};
	if (appAuth === 'loading') return <p>Please wait...</p>;
	if (!appAuth)
		return (
			<div
				style={{
					display: 'flex',
					minHeight: '70vh',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<h2>Please Sign In To Continue</h2>
			</div>
		);

	if (appAuth === true)
		return (
			<>
				<h2 style={{ fontWeight: '200' }} className='page-heading'>
					Welcome Mr. Glauco!
				</h2>
				<p className='page-subheading'>Post to your timeline</p>
				<div className='Page-grid'>
					<TimelineUpdateCard>
						<Form style={{ height: '100%' }} onSubmit={onSubmit}>
							<Form.Group style={{ height: 'calc(100% - 110px)' }}>
								{/* <Form.Label>Enter your full name:</Form.Label> */}
								<Form.Control
									as='textarea'
									rows='3'
									placeholder='Write your text here...'
									name='text'
									id='text'
									style={{ border: 'none', height: '100%' }}
									maxLength={500}
								/>
							</Form.Group>
							{/* <textarea name='text' onChange={handleChange} /> */}
							<button className='primary-button'>Post</button>
						</Form>
					</TimelineUpdateCard>
					<TimelineUpdatesList edit={true} />
				</div>

				<LatestReleaseCard
					id={latest_release.id}
					name={latest_release.name}
					image={latest_release.icon}
					year={latest_release.year}
					is_shop_available={latest_release.isShopAvailable}
					description={latest_release.description}
					title='Sevenk 2022'
				/>
			</>
		);
}

export default MakeTimelinePost;
