import React from 'react';
import Fade from 'react-reveal/Fade';

import { Col, Container, Image, Row } from 'react-bootstrap';
import artistsInfo from '../constants/artists';

export default function ArtistComponent() {
  return (
    <div>
      <Fade>
        <section>
          <Container>
            <h1 className='page-heading'>Artists</h1>
          </Container>
          <Container className='bio-container'>
            {artistsInfo.map((bio, i) => (
              <Row key={i} className='bio-section'>
                <Col lg={6} className='bio-info'>
                  <h3 className='text-center'>{bio.title}</h3>
                  <hr />
                  <p className='text-justify bio-text'>{bio.artists}</p>
                </Col>
                <Col lg={6} className='bio-image'>
                  <section className='polaroid'>
                    <Image src={bio.image} alt={bio.alt} />
                  </section>
                </Col>
              </Row>
            ))}
          </Container>
        </section>
      </Fade>
    </div>
  );
}
