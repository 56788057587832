import React from 'react';
import { Container } from 'react-bootstrap';
import { Fade } from 'react-reveal';
import pressLinksDetails from '../constants/press-links';

export default function PressLinks() {
  return (
    <Fade>
      <Container className="press-links">
        <h1 className="page-heading">Press links</h1>
        {pressLinksDetails.map((link, i) => (
          <div key={i} className="press-link">
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              <h3 className="press-link-title ">{link.title || 'Article on Glauco Lima'}</h3>
              <h4 className="press-link-source">Source: {link.source}</h4>
            </a>
          </div>
        ))}
      </Container>
    </Fade>
  )
}