import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Slide from 'react-reveal/Slide';
import { fb_auth, signUpWithGoogle } from '../fb';

function NavigationBar({ navLinks }) {
	const [visibility, setVisiblity] = useState('nav-hidden');
	const [appAuth, setAppAuth] = useState(false);

	const handleAuth = async () => {
		await signUpWithGoogle();
	};

	useEffect(() => {
		fb_auth.onAuthStateChanged((user) => {
			if (user) {
				if (user.email === 'info@glaucolima.com') setAppAuth(true);
				else fb_auth.signOut();
			}
		});
	});

	return (
		<div className='sidebar'>
			<button onClick={() => setVisiblity('nav-visible')}>
				<span className='menu-text'>MENU</span>
				<i className='bi bi-list'></i>
			</button>
			<Slide right when={visibility === 'nav-visible'} duration={350}>
				<div className={visibility}>
					<div className='close-nav' onClick={() => setVisiblity('nav-hidden')}>
						<i className='bi bi-x-lg'></i>
					</div>
					<ul className='list-even'>
						{navLinks.map((link, i) => (
							<li key={i} onClick={() => setVisiblity('nav-hidden')} className='nav-links'>
								<Link smooth to={link.path} id={link.id}>
									{link.text}
								</Link>
							</li>
						))}
						<li>Contact Me: info@glaucolima.com</li>
						{appAuth ? (
							<li>
								<Link smooth to={'/timeline'} id={'timeline'}>
									Manage Posts
								</Link>
							</li>
						) : (
							<li onClick={handleAuth} style={{ cursor: 'pointer' }}>
								Sign In
							</li>
						)}
					</ul>
				</div>
			</Slide>
		</div>
	);
}

export default NavigationBar;
