import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { workDetails } from '../constants/projects';
import fallbackImage from '../res/images/record.jpg';

const renderAlbumArt = (icon) => {
  if (icon) {
    return <Image src={icon} thumbnail />;
  } else {
    return <Image src={fallbackImage} thumbnail />;
  }
};

export default function ProjectComponent() {
  return (
    <Fade>
      <Container>
        <h1 className='page-heading oswald'>Projects</h1>
        <div className='projects'>
          {workDetails.map((work, i) => (
            <Fade>
              <div className="project-container">
                <h2 className='text-center role oswald'>{work.role}</h2>
                <Row>
                  {work.projects.map((project, i) => (
                    <Col key={i} className='project' xs={12} md={6} lg={4}>
                      <div key={i} className='project-details oswald'>
                        <div className='thumbnail-section'>
                          {renderAlbumArt(project.icon)}
                          <h3 className='project-title'>
                            {project.name}{project.year && (
                              <span> - {project.year}</span>
                            )}
                          </h3>
                        </div>
                        <div className='info-section'>
                          {project.links &&
                            project.links.map((link, index) => (
                              <>
                                {index ? ' | ' : ''}
                                <a
                                  key={index}
                                  href={link.url}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='project-links'>
                                  <span>{link.name}</span>
                                </a>
                              </>
                            ))}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Fade>
          ))}
        </div>
      </Container>
    </Fade>
  );
}
